/*

Theme styling, making it easier to make design changes to the whole site

*/

/* Font declarations */
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Voces&display=swap');
:root{
    --h1-font:'Bebas Neue', cursive;
    --h2-font:"";
    --link-font:'Bebas Neue', cursive;
    --p-font:'Voces', cursive;
}

/* Color declarations */

@media(prefers-color-scheme:dark){
    :root{
        --primary-color:#6f6f6f;
        --secondary-color:#800080;
        --bg-color:#282828;
        --h1-font-color:#eFeFeF;
        --h2-font-color:#cdcdcd;
        --p-font-color:#c5c5c5;
        --link-color:#e3e3e3;
        --error-color:#282828;
        --button-color:#282828;
        --hr-color:#787878;
}
}
@media(prefers-color-scheme:light){
    :root{
        --primary-color:#282828;
        --secondary-color:#800080;
        --bg-color:#ededed;
        --h1-font-color:#282828;
        --h2-font-color:#282828;
        --p-font-color:#282828;
        --error-color:#282828;
        --button-color:#282828;
        --hr-color:#282828;
}
}
