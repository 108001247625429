@import "colorPalette.css";
.navBarContainer{
    position:fixed;
    top:0;
    width:100%;
    text-align: left;
    background-color:var(--bg-color);
    color:var(--primary-color);
    padding-left:5px;
    padding-top:5px;
    padding-bottom:5px;
    border-bottom: 2px solid var(--hr-color);
    margin-bottom: 5px;
    vertical-align: middle;
}
a{
    text-decoration: none;
    color:var(--link-color);
    font-family: var(--link-font);
    font-size:2em;
    margin-left:10px;
    vertical-align: middle;
    border-color:var(--secondary-color);
    transition:0.1s
}
a:hover{
    color:var(--primary-color);
    border-bottom:solid 2px var(--secondary-color);
    transition:0.2s
}
.navLogo{
    vertical-align: middle;
}
.navLogo img{
    vertical-align: middle;
    height:20px;
    width:fit-content;
    border-right:2px var(--primary-color) solid;
    background-color: var(--bg-color);
    transition:0.2s;
    /* height:40px; */
}
.navLogo img:hover{
    background-color: var(--secondary-color);
    transition:0.2s
}
@media (min-width: 1400px) {
    .navLogo img{
       
        height:2em;
       
       
        /* height:40px; */
    }
  }
@media (min-width:600px){
    .navLogo img{
       
        height:2em;
        
       
        /* height:40px; */
    }
}