@import "colorPalette.css";

.homeContainer{
    margin:0px;
    margin-top:3em;
    background-color: var(--secondary-color);
    padding-bottom:10px;
}
img{
    width:100%;
}
.contentContainer{
    margin:0px;
    background-color: var(--secondary-color);
   
}
.content{
    background-color: var(--bg-color);
    margin-top:20px;
    margin-left:0%;
    margin-right:0%;
    margin-bottom:30px;
    padding-bottom:10px;
    overflow-wrap:break-word;
    overflow:clip;
}
.languageLogos{
    color:var(--p-font-color);
    font-size:3em;
    margin-left:5px;
    margin-right:5px;
}
.languageLogos i{
    padding-top:10px;
    margin-left:10px;
    margin-right:10px;
}
h1{
    margin:0px;
    color:var(--h1-font-color);
    font-family: var(--h1-font);
    font-size: 5em;
}
h2{
    color:var(--h2-font-color);
}
p{
    color:var(--p-font-color);
    font-family: var(--p-font);
    margin-left:50px;
    margin-right:50px;
    font-size:1.5em;
}
@media (min-width: 1400px) {
    .homeImg{
        width:1400px;
    }
    .content{
        background-color: var(--bg-color);
        margin-top:20px;
        margin-left:20%;
        margin-right:20%;
        margin-bottom:30px;
        padding-bottom:10px;
        overflow-wrap:break-word;
        overflow:clip;
    }
  }
@media (min-width:600px){
    .homeImg{
        width:600px;
    }
    .content{
        background-color: var(--bg-color);
        margin-top:20px;
        margin-left:10%;
        margin-right:10%;
        margin-bottom:30px;
        padding-bottom:10px;
        overflow-wrap:break-word;
        overflow:clip;
    }
}